@tailwind utilities;

.navigationMenuExpanded {
  z-index: 2;
  height: 400px;
  transition: height .2s ease-in-out;
}

/**
  * @TODO ROLLOUT_FLAGS
  * Remove this after we no longer need that flag
  * and update `navigationMenuExpanded.height` to 650px
*/
.navMenuExpanded_0 {
  height: 316px;
}

.navMenuExpanded_1 {
  height: 400px;
}

.navMenuExpanded_2 {
  height: 484px;
}

.navMenuExpanded_3 {
  height: 566px;
}

.navigationMenuCollapsed {
  height: 4.5rem;
  transition: height .15s  ease-in-out;
}

.navigationMenuCollapsedNative {
  height: calc(60px + env(safe-area-inset-bottom));
}

.navigationBodyDisappear {
  opacity: 0;
  transition: opacity .15s  ease-in-out;
}

.navigationBodyAppear {
  opacity: 1;
  transition: opacity .15s  ease-in-out;
}

.navigationMenuFallback {
  z-index: -1;
}

.navigationMenuComeForward {
  z-index: 2;
}

.desktopNavItemBase {
  background: linear-gradient(to right, #171817 50%, black 50%);
  background-size: 200% 100%;
  transition: background-position .1s linear;
  background-position: right bottom;
}

.desktopNavItemActive {
  background-position: left bottom;
}