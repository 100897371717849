.square {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.mask {
  width: 100%;
  height: 100%;
}

.fill {
  position: relative;
  background-color: #b6b9b7;
  top: -150px;
  width: 100%;
  height: 100%;
  clip-path: url(#squareMask);
}

.active-fill {
  transform: rotate(60deg);
  height: 450px;
  position: relative;
  width: 600px;
  background-color: #1ebf51;
  bottom: -440px;
  left: -440px;
  animation: moveUp 1s ease-in infinite;
}

@keyframes moveUp {
  0% {
    bottom: -440px;
    left: -440px;
  }
  1% {
    bottom: -440px;
    left: -440px;
  }
  99% {
    bottom: 300px;
    left: 0px;
  }
  100% {
    bottom: 300px;
    left: 0px;
  }
}
